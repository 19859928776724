<script setup>
import {getCurrency, isNullOrEmpty, logout, moveArrayValueToPosition,} from "@Res/js/helpers";
import {computed, onMounted, ref} from "vue";
import CartModal from "@Res/js/Pages/Storefront/Partials/CartItems/Modal.vue";
import LoginModal from "@Res/js/Pages/Storefront/Partials/LoginModal.vue";
import DropdownLink from "@Components/DropdownLink.vue";
import Dropdown from "@Components/Dropdown.vue";
import Button from "@Components/Button.vue";
import AnchorLink from "@Components/AnchorLink.vue";
import ALink from "@Components/ALink.vue";
import clampJs from "clamp-js";
import {router} from "@inertiajs/vue3";

const props = defineProps({
    title: String,
    store: Object,
    fluid: {
        type: Boolean,
        default: false,
    },
    showBreadcrumb: {
        type: Boolean,
        default: true,
    },
    canNavigateToProducts: {
        type: Boolean,
        default: true,
    },
});

const selectedCurrency = computed(() => getCurrency(props.store.currency));

const storeCurrencies = moveArrayValueToPosition(
    props.store.currencies,
    (v) => v === props.store.currency,
    0
).map((code) => getCurrency(code));

const showCart = defineModel('showCart', { default: false });
const showLogin = defineModel('showLogin', { default: false });
const titleRef = ref(null);

const logoutUser = async () => await logout(() => window.location.reload());

const setCurrency = (currency) => {
    router.post(
        route("storefront.set-currency", {
            store_slug: props.store.slug,
            currency,
        }),
        {},
        {
            onError: (error) =>
                notify({text: Object.values(error)[0], type: "error"}),
        }
    );
};

onMounted(() => {
    if (props.showBreadcrumb) clampJs(titleRef.value, {clamp: 1});
});
</script>

<template>
    <CartModal :show="showCart" @close="showCart = false"/>
    <LoginModal :show="showLogin" @close="showLogin = false"/>

    <div class="sticky top-0 z-40 w-full">
        <div
            class="relative z-10 bg-white border-b border-gray-100 shadow dark:bg-neutral-900 dark:border-gray-700"
        >
            <div :class="`${fluid ? 'container-fluid' : 'container'}`">
                <div class="flex justify-between h-16 space-x-6">
                    <div class="flex items-center overflow-hidden lg:flex-1">
                        <AnchorLink
                            :href="
                                route(
                                    'storefront.index',
                                    $page.props.store.slug
                                )
                            "
                            class="flex-shrink-0 inline-block text-gray-600"
                        >
                            <span
                                v-if="!$page.props.store.logoUrl"
                                class="text-2xl font-bold text-gray-800"
                            >{{ $page.props.store.name }}</span
                            >
                            <img
                                v-else
                                :src="$page.props.store.logoUrl"
                                alt=""
                                class="h-10"
                            />
                        </AnchorLink>
                    </div>

                    <div class="flex items-center justify-end flex-1 space-x-7">
                        <Dropdown align="center" widthClass="w-96">
                            <template #trigger>
                                <div class="flex space-x-3">
                                    <button
                                        class="inline-flex items-center text-sm font-semibold leading-4 text-gray-500 transition outline-none hover:text-gray-700"
                                        type="button"
                                    >
                                        {{ selectedCurrency.code }}

                                        <svg class="size-4 ml-2 -mr-0.5">
                                            <use href="#icon-chevron-down"/>
                                        </svg>
                                    </button>
                                </div>
                            </template>

                            <template #content>
                                <div class="grid grid-cols-4 p-2 gap-x-1">
                                    <DropdownLink
                                        v-for="currency in storeCurrencies"
                                        :class="[
                                            'text-xs text-center !py-2 !px-3 !rounded-full',
                                            {
                                                'font-bold':
                                                    currency.code ===
                                                    selectedCurrency.code,
                                            },
                                        ]"
                                        as="button"
                                        @click.prevent="
                                            setCurrency(currency.code)
                                        "
                                    >
                                        {{ currency.code }} ({{
                                            currency.symbol
                                        }})
                                    </DropdownLink>
                                </div>
                            </template>
                        </Dropdown>

                        <!-- <div class="h-5 border-l border-gray-300" /> -->

                        <ALink
                            class="space-x-1 page-link"
                            title="Cart"
                            @click.prevent="showCart = true"
                        >
                            <span
                                v-if="$page.props?.cart?.items.length > 0"
                                class="size-4 flex items-center justify-center bg-primary-500 absolute -top-2 -right-2 rounded-full text-[10px] leading-none text-white font-medium"
                            >
                                <span
                                    class="mt-[1px]"
                                    v-text="
                                        $page.props?.cart?.items.reduce(
                                            (accumulator, currentValue) =>
                                                accumulator +
                                                currentValue.quantity,
                                            0
                                        )
                                    "
                                />
                            </span>

                            <svg class="size-6 md:size-4" fill="none">
                                <use href="#icon-shopping-cart-outline"/>
                            </svg>

                            <span class="hidden md:flex">Cart</span>
                        </ALink>

                        <div class="hidden h-5 border-l border-gray-300"/>

                        <template v-if="isNullOrEmpty($page.props.user)">
                            <Button
                                class="hidden md:flex"
                                fit
                                kind="primary"
                                size="sm"
                                title="My Account"
                                @click.prevent="showLogin = true"
                            >My Account
                            </Button
                            >

                            <ALink
                                class="flex items-center justify-center text-gray-600 rounded-full cursor-pointer md:hidden dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-800 focus:outline-none"
                                title="My Account"
                                @click.prevent="showLogin = true"
                            >
                                <svg class="size-6">
                                    <use href="#icon-user-outline"/>
                                </svg>
                            </ALink>
                        </template>

                        <div v-else class="relative ml-3">
                            <Dropdown align="right" widthClass="w-52">
                                <template #trigger>
                                    <div class="flex space-x-3">
                                        <button
                                            v-if="
                                                $page.props.jetstream
                                                    .managesProfilePhotos
                                            "
                                            class="flex transition border-2 rounded-full outline-none border-primary-700"
                                            type="button"
                                        >
                                            <img
                                                :alt="$page.props.user.name"
                                                :src="
                                                    $page.props.user
                                                        .profilePhotoUrl
                                                "
                                                class="object-cover rounded-full size-7"
                                            />
                                        </button>
                                    </div>
                                </template>

                                <template #content>
                                    <!-- Account Management -->
                                    <div
                                        class="block px-4 py-2 text-xs text-gray-700"
                                    >
                                        <div class="font-medium">
                                            {{ $page.props.user.name }}
                                        </div>
                                        <div>{{ $page.props.user.email }}</div>
                                    </div>

                                    <div class="border-t border-gray-100"/>

                                    <DropdownLink
                                        :href="route('contact-us')"
                                        as="a"
                                    >
                                        Help & Support
                                    </DropdownLink>

                                    <div class="border-t border-gray-100"/>

                                    <!-- Authentication -->
                                    <form @submit.prevent="logoutUser">
                                        <DropdownLink as="button">
                                            Log Out
                                        </DropdownLink>
                                    </form>
                                </template>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        v-if="showBreadcrumb"
        :class="`${fluid ? 'container-fluid' : 'container'} mt-8`"
    >
        <nav aria-label="breadcrumb" class="block w-full">
            <ol class="flex items-center w-full">
                <li
                    class="flex items-center leading-normal transition-colors duration-300 cursor-pointer text-blue-gray-900 hover:text-primary-500"
                >
                    <AnchorLink
                        :href="
                            route('storefront.index', $page.props.store.slug)
                        "
                        class="opacity-60"
                    >
                        <svg class="size-4">
                            <use href="#icon-home-solid"/>
                        </svg>
                    </AnchorLink>
                    <span
                        class="mx-2 text-sm leading-normal text-gray-500 pointer-events-none select-none"
                    >/</span
                    >
                </li>
                <li
                    class="flex items-center leading-normal transition-colors duration-300 text-blue-gray-900 hover:text-primary-500"
                >
                    <template v-if="canNavigateToProducts">
                        <AnchorLink
                            :href="
                                route(
                                    'storefront.index',
                                    $page.props.store.slug
                                )
                            "
                            class="opacity-60"
                            v-text="`Products`"
                        />
                        <span
                            v-if="title"
                            class="mx-2 text-sm leading-normal text-gray-500 pointer-events-none select-none"
                        >/</span
                        >
                    </template>
                </li>
                <li v-if="title">
                    <span
                        ref="titleRef"
                        class="font-medium text-gray-600"
                        v-text="title"
                    />
                </li>
            </ol>
        </nav>
    </div>
</template>

<style lang="scss" scoped>
.page-link {
    // @apply size-8 sm:w-10 sm:h-10 rounded-full;
    @apply text-sm font-medium;
    @apply text-gray-700 dark:text-gray-500;
    @apply inline-flex items-center justify-center focus:outline-none;
    @apply focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 relative cursor-pointer;
}

.current-link {
    @apply text-primary;

    &::after {
        content: "";
        @apply bg-primary-400 rounded-full w-2 h-[2px] absolute -bottom-1;
    }
}
</style>
